import React, { useState } from 'react';
import {
  Table, TableCheckbox, TableRow, TableWrap, TablePagination, TableCell,
} from '@/shared/components/MaterialTableElements';
import { Checkbox } from '@material-ui/core';
import { Button } from '@/shared/components/Button';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import Badge from '@/shared/components/Badge';
import SettingsIcon from 'mdi-react/SettingsIcon';
import { completeOrder } from '../../../utils/api/task';
import Modal from '../../../shared/components/Modal';


export default function SingleOrder({ select, handleClick, item }) {
  const history = useHistory();

  let payClass = 'warning';
  if (item.is_legal_client) {
    payClass = 'primary';
  } else if (item.pay_status === 3) {
    payClass = 'success';
  } else if (item.pay_status === 4) {
    payClass = 'success';
  }

  let jobClass = 'warning';
  const jobStatus = item.job_status;
  if (jobStatus === 'in_progress') {
    jobClass = 'primary';
  } else if (jobStatus === 'done') {
    jobClass = 'success';
  } else if (jobStatus === 'canceled') {
    jobClass = 'outline-secondary';
  }

  return (
    <TableRow
      onClick={event => handleClick(event, item.id)}
      aria-checked={select}
      tabIndex={-1}
      key={item.id}
      selected={select}
    >

      <CustomTableCell
        right
        component="th"
        scope="row"
        padding="none"
      >
        <div>
          {`  #${item.order_id}`}
          <br />
          <CarNumber bg="primary">
            {item.car.plate_number}
          </CarNumber>
          <br />
          {item.car.model_text}
        </div>
        <div>
          <br />
          <Button variant="outline-secondary" size="sm" onClick={() => history.push(`/cashier/task/${item.order_id}`)}><SettingsIcon /> Просмотр</Button>
        </div>
      </CustomTableCell>
      <CustomTableCell right>
        {item.client.phone}
      </CustomTableCell>
      <CustomTableCell right>
        {item.car.keys}
      </CustomTableCell>
      <CustomTableCell right>
        <div>
          <Badge bg={jobClass}>{item.job_status_text}</Badge>
        </div>
      </CustomTableCell>
      <CustomTableCell right>
        {item.datetime}
      </CustomTableCell>
      <CustomTableCell right>
        <div>
          <Badge bg={payClass}>{item.pay_status_text}</Badge>
        </div>
      </CustomTableCell>
    </TableRow>
  );
}


const CustomTableCell = styled(TableCell)`
font-size: 18px !important;
span{
font-size: 18px !important;

}
`;

const CarNumber = styled(Badge)`
font-size:14px`;
